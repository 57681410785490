import { GOOGLE_OIDC_COOKIE_KEY } from "../constants/value";

export const GetTokenFromCookies = () => {
  const cookieName = `${GOOGLE_OIDC_COOKIE_KEY}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return null;
}