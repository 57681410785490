import React from "react"
import { generateClassName } from "src/lib/ui/class-name-helper"
import styled from "styled-components"
import { color } from "@smoothincorg/smooth-ui"

type Props = {
  className?: string
  height?: number
  color?: keyof typeof color
}

const { getRootClassName } = generateClassName("Border")

const Component: React.FC<Props> = (props) => {
  return <span className={getRootClassName(props)} />
}

const StyledComponent = styled(Component)`
  height: ${(props) => props.height || 1}px;
  background-color: ${(props) => color[props.color || "mediumLightGray"]};
  display: block;
`

export const Border = StyledComponent
