import { generateClassName } from "@smoothincorg/smooth-ui"

const { getRootClassName } = generateClassName("Logo")

type Props = {
  className?: string
}

export const Logo: React.FC<Props> = (props) => {
  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      className={getRootClassName(props)}
      src={`/images/logo-min.svg`}
      placeholder="empty"
      alt="smooth"
      width={144}
      height={28}
    />
  )
}
