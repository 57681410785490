export const ENV_NAMES = {
  local: "local",
  development: "development",
  staging: "staging",
  production: "production",
} as const

const currentEnvName = process.env.ENV_NAME

export type EnvName = (typeof ENV_NAMES)[keyof typeof ENV_NAMES]

const getEnvName = (): EnvName => {
  const envName = ENV_NAMES[currentEnvName as keyof typeof ENV_NAMES]
  if (envName) return envName
  throw new Error("wrong env name: " + currentEnvName)
}

export const ENV_NAME = getEnvName()
