// import { getIdToken } from 'src/features/auth'
import { axios } from "src/lib/axios"
import axiosRetry from "axios-retry"
import { useAuthState } from "src/features/auth/hooks/useAuthState"
import { GetTokenFromCookies } from "src/lib/utils/cookies"

axiosRetry(axios, { retries: 3 })

export const useSmoothApi = () => {
  const { auth } = useAuthState()

  const getWithToken: typeof axios.get = async (url, config) => {
    const idToken = GetTokenFromCookies()
    if (!idToken) throw new Error("No idToken")

    axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`

    return axios.get(url, {
      ...config,
    })
  }

  const postWithToken: typeof axios.post = async (url, data, config) => {
    const idToken = GetTokenFromCookies()
    if (!idToken) throw new Error("No idToken")

    axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`

    return axios.post(url, data, {
      ...config,
    })
  }

  const putWithToken: typeof axios.put = async (url, data, config) => {
    const idToken = GetTokenFromCookies()
    if (!idToken) throw new Error("No idToken")

    axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`

    return axios.put(url, data, {
      ...config,
    })
  }

  const patchWithToken: typeof axios.patch = async (url, data, config) => {
    const idToken = GetTokenFromCookies()

    axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`

    return axios.patch(url, data, {
      ...config,
    })
  }

  const deleteWithToken: typeof axios.delete = async (url, config) => {
    const idToken = GetTokenFromCookies()
    if (!idToken) throw new Error("No idToken")

    axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`

    return axios.delete(url, {
      ...config,
    })
  }

  return {
    postWithToken,
    deleteWithToken,
    getWithToken,
    patchWithToken,
    putWithToken,
  }
}
