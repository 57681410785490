"use client"
import React, { useCallback, useMemo } from "react"
import styled from "styled-components"
import { useState } from "react"
import { color, ma, mq, mq_min, Text } from "@smoothincorg/smooth-ui"
import { BsFiletypeCsv, BsPersonCircle } from "react-icons/bs"
import { boxShadow } from "src/lib/styles/variable"
import { Border } from "src/components/Border"
import { Logo } from "src/components/Logo"
import { Smoother } from "src/features/auth/hooks/useCurrentSmoother"
import { SnackBarCard } from "src/lib/ui/snack-bar-card"
import { IS_NEXT } from "src/lib/constants/value"
import { usePathname } from "next/navigation"
import Image from "next/image"
import { BiChat, BiStore, BiUser } from "react-icons/bi"
import { HiOutlineDesktopComputer } from "react-icons/hi"
import { GiSawedOffShotgun } from "react-icons/gi"
import { AiFillDashboard } from "react-icons/ai"
import { RiAdminLine } from "react-icons/ri"
import { MdOutlineAccountTree } from "react-icons/md"
import { TbBrandFirebase } from "react-icons/tb"
import Link from "next/link"

const HEADER_HEIGHT = 90

type Props = {
  currentSmoother: Smoother
  isAdmin: boolean
  className?: string
  replace:
    | ReturnType<typeof import("next/navigation")["useRouter"]>["replace"]
    | ReturnType<typeof import("next/router")["useRouter"]>["replace"]
  pathname: ReturnType<typeof usePathname>
}

const EMPLOYEE_MENU_ITEMS: [JSX.Element | null, string, string | [string, string][]][] = [
  [<BiChat key={"chat"} />, "チャット", "/admin/chat"],
  [<HiOutlineDesktopComputer key={"desktop"} />, "審査", "/admin/for-cic"],
  [
    <BiUser key={"user"} />,
    "ユーザー",
    [
      ["ユーザー一覧", "/admin/users"],
      ["遅延ユーザー一覧", "/admin/users/defaulters"],
    ],
  ],
  [<BsFiletypeCsv key={"csv"} />, "CIC用CSV", "/admin/fetch-cic-csv"],
  [<GiSawedOffShotgun key={"shotgun"} />, "契約LIFF送付", "/admin/lifftter"],
  [<BiStore key={"store"} />, "加盟店リスト", "/admin/stores"],
  // [null, "遅延ページ", "/admin/contract-with-late-charge"],
]

const ADMIN_MENU_ITEMS: [JSX.Element | null, string, string][] = [
  [<AiFillDashboard key={"dashboard"} />, "ダッシュボード", "/admin/dashboards"],
  [<RiAdminLine key={"admin"} />, "Smoother", "/admin/smoothers"],
  [
    <Image key={"openai"} src="/images/openai-icon.svg" width={20} height={20} alt="openai" />,
    "GPT",
    "/admin/gpt",
  ],
  [<MdOutlineAccountTree key={"account"} />, "Firebase List", "/admin-auth/firebase-auth-users"],
  [<TbBrandFirebase key={"firebase"} />, "Firebase Self", "/admin-auth/current-user-info"],
]

const Component: React.FC<Props> = ({ pathname, replace, ...props }) => {
  const [open, setOpen] = useState(false)
  const [hovered, setHovered] = useState("")

  const betaToggleHref = useMemo(() => {
    if (IS_NEXT) {
      return "https://cs.smooth.jp" + pathname
    } else {
      return "https://cs.next.smooth.jp" + pathname
    }
  }, [pathname])

  const onSignOutClick = useCallback(async () => {
    SnackBarCard.fire({
      title: "ログアウトしました。",
    })

    sessionStorage.clear()

    await fetch("/api/logout")

    replace(
      "/admin/sign-in?" +
        new URLSearchParams({
          "signed-out": "1",
          "redirect-to": pathname,
        })
    )
  }, [pathname, replace])

  const MENU_ITEMS = useMemo(() => {
    if (props.isAdmin) {
      return [...EMPLOYEE_MENU_ITEMS, ...ADMIN_MENU_ITEMS]
    } else {
      return EMPLOYEE_MENU_ITEMS
    }
  }, [props.isAdmin])

  return (
    <div
      className={`${props.className} smooth-ui-Header`}
      style={{
        background: IS_NEXT ? color.black : "linear-gradient(45deg, #6EC3D8 0%, #52a4b8 100%)",
        height: HEADER_HEIGHT,
      }}
    >
      <div className={`${props.className}__content`}>
        <div className={`${props.className}__top`}>
          <Logo />
          <div className={`${props.className}__smoother`}>
            <button className={`${props.className}__toggle`} onClick={() => setOpen((d) => !d)}>
              <BsPersonCircle fill={color.white} />
            </button>
            {open && (
              <div className={`${props.className}__toggle-menu`}>
                <Text size={14} color="black">
                  Logged in as：{props.currentSmoother?.name}
                </Text>
                <Border />
                <button onClick={onSignOutClick}>
                  <Text size={14} color="black">
                    ログアウト
                  </Text>
                </button>
                <button onClick={onSignOutClick}>
                  <Text size={14} color="black">
                    超ログアウト
                  </Text>
                </button>
                <a href={betaToggleHref}>
                  <Text size={14} color="black">
                    {IS_NEXT ? `安定版を使う！` : `ベータ版を使う！`}
                  </Text>
                </a>
                {props.currentSmoother?.roleType == "admin" && (
                  <button
                    onClick={() => {
                      setOpen((d) => !d)
                    }}
                  >
                    <Text size={14} color="black">
                      Admin
                    </Text>
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        <nav className={`${props.className}__menu text-2xl`}>
          {MENU_ITEMS.map((d) => {
            const [, name, link] = d

            if (typeof link !== "string") {
              const isCurrentLocation = pathname.startsWith(link[0][1])

              return (
                <div
                  key={link[0][1]}
                  className="relative mr-4 flex justify-end"
                  onMouseEnter={() => setHovered(name)}
                  onMouseLeave={() => setHovered("")}
                >
                  <Link
                    key={link[0][1]}
                    href={link[0][1]}
                    className="py-2"
                    prefetch={false}
                    data-is-current={isCurrentLocation}
                    style={{
                      color: isCurrentLocation ? color.white : color.lightGray,
                      borderBottom: isCurrentLocation
                        ? `2px solid #ffffff`
                        : "2px solid transparent",
                    }}
                  >
                    {name}
                  </Link>
                  <div
                    className={`${props.className}__sub-menu absolute left-0 top-[100%] z-10 w-[300px] flex-col rounded bg-white px-4 py-4 shadow`}
                    style={{
                      display: hovered === name ? "flex" : "none",
                    }}
                  >
                    {link.map((d) => {
                      const [name, link] = d

                      const isCurrentLocation = pathname.startsWith(link)

                      return (
                        <Link
                          key={link}
                          href={link}
                          data-is-current={isCurrentLocation}
                          className="py-2 underline"
                          prefetch={false}
                        >
                          {name}
                        </Link>
                      )
                    })}
                  </div>
                </div>
              )
            }

            const isCurrentLocation = pathname.startsWith(link)

            return (
              <Link
                key={link}
                href={link}
                data-is-current={isCurrentLocation}
                className="mr-4 py-2"
                prefetch={false}
                style={{
                  color: isCurrentLocation ? color.white : color.lightGray,
                  borderBottom: isCurrentLocation ? `2px solid #ffffff` : "2px solid transparent",
                }}
              >
                {name}
              </Link>
            )
          })}
        </nav>
      </div>
    </div>
  )
}

export const Header = styled(Component)`
  &__content {
    ${ma};
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0px 5%;

    ${mq("sp_wide")} {
      width: 90%;
    }
  }

  &__top {
    width: 100%;
    margin-right: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    > .smooth-ui-Logo {
      height: 24px;
      width: auto;
      margin-right: 16px;
    }
  }

  &__smoother {
  }

  &__menu {
    display: flex;
    flex-wrap: wrap;

    /* > a,
    > button {
      margin-right: 16px;
      font-size: 14px;
      padding: 4px 0;
      line-height: 1.6rem;
      color: ${color.white};
      fill: currentColor;

      &[data-is-current='true'] {
        border-bottom: 4px solid ${color.white};
      }
    } */
  }

  &__toggle {
    height: 30px;

    > svg {
      width: 30px;
      height: 100%;
    }
  }

  &__toggle-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: ${HEADER_HEIGHT - 20}px;
    right: calc((100vw - 1000px) / 2);
    z-index: 3;
    background-color: #ffffff;
    ${boxShadow.normal};
    min-width: 200px;
    border-radius: 4px;

    ${mq("sp_wide")} {
      right: 4px;
    }

    .smooth-ui-Border {
      width: 100%;
    }

    > .smooth-ui-Text,
    > a,
    > button {
      text-align: left;
      width: 100%;
      padding: 10px;
    }

    > button {
      &:hover {
        background-color: ${color.lightGray};
      }
    }
  }

  &__hamburger {
    display: none;
    ${mq_min("sp_wide")} {
    }
  }
`
