"use client"
import React from "react"
import { ReactNode } from "react"
import styled from "styled-components"
import { Header as HeaderComponent } from "src/components/Header"
import { Auth, useCurrentSmoother } from "src/features/auth"
import { useRouter } from "next/router"

type Props = {
  className?: string
  children?: ReactNode
}

const Header: React.FC = () => {
  const router = useRouter()
  const { isAdmin, smoother } = useCurrentSmoother()

  return (
    <HeaderComponent
      pathname={router.pathname}
      replace={router.replace}
      isAdmin={isAdmin}
      currentSmoother={smoother}
    />
  )
}

const Component: React.VFC<Props> = (props) => {
  return (
    <div className={props.className}>
      <Auth>
        <Header />
      </Auth>
      {props.children}
    </div>
  )
}

const StyledComponent = styled(Component)`
  position: relative;
  min-height: 100vh;
  background-color: #ffffff;

  .react-slidedown {
    height: 0;
    transition-property: none;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
  }

  .react-slidedown.transitioning {
    overflow-y: hidden;
  }

  .react-slidedown.closed {
    display: none;
  }
`

export const DefaultLayout = StyledComponent
