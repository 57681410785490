import { z } from "zod"
import useSWR from "swr"
import { useContext } from "react"
import { AuthContext } from ".."

export const idTokenSchema = z.object({
  token: z.string().nullable(),
})

export const useAuthState = () => {
  const { auth, setAuth } = useContext(AuthContext)

  const smootherSWR = useSWR(auth?.tenant ? "/api/auth/current-user" : null, async () => {
    const data = {
      uid: auth.tenant.uid,
      customClaims: auth.tenant?.customClaims,
    }

    return data
  })

  return {
    validatedCurrentSmoother: smootherSWR?.data,
    auth,
    setAuth,
  }
}
