import { Dictionary } from "./types"

type Entries<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T][]

export const entries = <T extends object>(object: T) => {
  return Object.entries(object) as Entries<T>
}

type Cast<X, Y> = X extends Y ? X : Y

type FromEntries<T> = T extends [infer Key, unknown][]
  ? { [K in Cast<Key, string>]: Extract<T[number], [K, unknown]>[1] }
  : never

export const fromEntries = <T extends unknown[][]>(object: T) => {
  return Object.fromEntries(object) as FromEntries<T>
}

export const isDictionary = (val: any): val is Dictionary => {
  return val !== null && typeof val === "object" && val.constructor === Object
}

export const shouldReturnDirectory = (val: any) => {
  return [null, undefined, ""].includes(val)
}
