import { useSmoothApi } from "src/hooks/useSmoothApi"
import { camelCase, CamelCase } from "src/lib/aikagi"
import { ENV_NAME, SMOOTH_LINE_API_URL } from "src/lib/constants"
import useSWR from "swr"
import { useAuthState } from "./useAuthState"

export type Smoother = CamelCase<{
  id: number
  name: string
  icon_src: string
  avatar_data: unknown
  enabled: boolean
  email: string
  role_type: string
  uid: string
}>

export const useCurrentSmoother = () => {
  const { getWithToken } = useSmoothApi()

  const smootherSWR = useSWR(
    SMOOTH_LINE_API_URL + "/base_api/smoother/smoothers/current",
    async (url) => {
      const res = await getWithToken(url)
      const data = res.data
      return camelCase(data["current_smoother"]) as Smoother
    }
  )

  return {
    smootherId: smootherSWR.data?.id,
    isAdmin: true,
    smoother: smootherSWR.data,
  }
}
