const PREFIX = "smooth-ui" as const

type UIClassName<N extends string> = `${typeof PREFIX}-${N}`

type ScClassName<P extends { className?: string }> = P extends {
  className: infer C
}
  ? C
  : undefined

export const generateClassName = <T extends string>(componentName: T) => {
  const uiClassName: UIClassName<T> = `${PREFIX}-${componentName}`

  const getRootClassName = <P extends { className?: string }>(
    props: P
  ): `${UIClassName<T>} ${ScClassName<P>}` => {
    const scClassName = props.className as ScClassName<P>

    return `${uiClassName} ${scClassName}`
  }
  return {
    getRootClassName,
    className: uiClassName,
  }
}
